import React, { Component } from 'react'
import Link from '../utils/link'

import NewsletterForm from '../components/newsletter-form'

import { LogoMark } from '../components/icons'


class Footer extends Component {
  render() {
    return (
    <>
      <NewsletterForm/>

      <footer className='footer'>
        <div className='footer__inner'>

        <div className='footer__col'>
          <Link to='/' title='Howes & Landino'>
            <span className="logo-mark">
              <LogoMark color={'#1D1D1B'} />
            </span>
          </Link>
        </div>

          <div className='footer__col'>
            <nav className='footer__nav'>
              <ul>
                <li><Link to='/about'>About</Link></li>
                <li><Link to='/enquiries/'>Newsletter</Link></li>
                <li><Link to='https://www.instagram.com/taylorhowesdesigns'>Instagram</Link></li>
                <li><Link to='/legal'>Legal</Link></li>
                <li><Link to='/enquiries'>Enquiries</Link></li>
                {/* <li>Site by&nbsp;<Link to='https://studiosmall.com'>StudioSmall</Link></li> */}
                <li>
                  <Link to='/' title='Taylor Howes' className='footer__logo'>
                    Howes & Landino &copy; {new Date().getFullYear()}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </footer>
      </>
    )
  }
}

export default Footer
