import React, { useState } from 'react';
import PropTypes from 'prop-types'

import layoutQuery from "../hooks/use-layout-query"

import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'
import Landing from '../components/landing'
// import { Link } from 'gatsby'
import Link from '../utils/link'
import SortContext from '../utils/SortContext';

const Layout = ({ children, location, pageContext }) => {
  const data = layoutQuery()

  const [selectedSortBy, setSelectedSortBy] = useState('');
  const handleSortBy = (value) => {
    setSelectedSortBy(value);
    console.log('Header File: Selected Sort By:', value);
  };

  return (
    <>
      <Header location={location} data={data} pageContext={pageContext} setSortBy={handleSortBy} />

        <Landing data={data} />

        <div className='side left'>
          <div className='side__inner'>
            <Link to='https://studiohowes.co.uk'>Studio Howes</Link>
          </div>
        </div>

        <main>
          <SortContext.Provider value={selectedSortBy}>
          { children }
          </SortContext.Provider>
        </main>

        <div className='side right'>
          <div className='side__inner'>
            <Link to='https://taylorhowes.co.uk'>Taylor Howes</Link>
          </div>
        </div>
      <Footer data={data} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
