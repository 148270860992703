import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';


//import { useNavigate } from 'react-router-dom'; // Import useNavigate

import { Link } from 'gatsby';
//import Link from '../utils/link';

import { useLocation } from '@reach/router'; // Update the import statement

import { Logo, LogoMark, Close } from '../components/icons';
import SortContext from '../utils/SortContext';

const Header = ({ setSortBy }) => {
  const [scrollDirection, setScrollDirection] = useState('none');
  const [scrolled, setScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [offCanvas, setOffCanvas] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [activeParent, setActiveParent] = useState(null);
  const [selectedSortBy, setSelectedSortBy] = useState('');
  const [activeSubmenuMobile, setActiveSubmenuMobile] = useState('');

   // Get the current location using the useLocation hook from Gatsby
   const location = useLocation();

   // Check if the current location is the home page ("/")
   const isHomePage = location.pathname === '/';

   console.log(location)
   console.log(isHomePage)

  let lastScrollTop = 0; // Declare the variable using 'let'

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrolled = scrollTop >= 50;
    let direction = 'none';

    if (scrollTop >= 100) {
      if (scrollTop > lastScrollTop) {
        direction = 'down';
      } else {
        direction = 'up';
      }
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;

    setScrollDirection(direction);
    setScrolled(scrolled);
  };

  const handleHeaderHover = () => {
    setIsHovered((prevIsHovered) => !prevIsHovered);
    setScrollDirection('none');
  };

  const handleSubMenuClick = (link) => {
    setActiveSubMenu((prevActiveSubMenu) =>
      prevActiveSubMenu === link ? null : link
    );
    setActiveParent(link === 'contact' ? 'contact' : null);
  };

  const handleLinkClick = (submenu) => {
    setActiveSubmenuMobile((prevActiveSubmenuMobile) =>
      prevActiveSubmenuMobile === submenu ? '' : submenu
    );
  };

  const hideOffCanvas = () => {
    setOffCanvas(false);
  };

  const toggleOffCanvas = () => {
    setOffCanvas((prevOffCanvas) => !prevOffCanvas);
  };

  // const navigate = useNavigate();

  const handleButtonClick = (value) => {
    setSelectedSortBy(value);
  
    const currentUrl = window.location.pathname;
    const isSingleJournalPost =
      currentUrl.startsWith('/journal/') && currentUrl !== '/journal/';
  
    if (isSingleJournalPost) {
      // Handle filter on the single journal page without leaving the page
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('sort', value);
      const newUrl = `/journal/?${searchParams.toString()}`;
      navigate(newUrl, { replace: true });
    } else {
      // Handle filter on the single journal page without leaving the page
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('sort', value);
      const newUrl = `/journal/?${searchParams.toString()}`;
      navigate(newUrl, { replace: true });
    }
  };

  useEffect(() => {
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerClass = `header${
    scrollDirection !== 'none' ? ` scrolled-${scrollDirection}` : ''
  }${scrolled ? ' scrolled' : ''}${isHovered ? ' scrolled-up' : ''}`;

  const submenuContainerClass = `header__nav${activeSubMenu ? ' active' : ''}`;

  const parentLinkClasses = {
    gallery: isHomePage ? 'active' : '', // Add 'active' class if it's the home page
    makers: activeParent === 'makers' ? 'active' : '',
    about: activeParent === 'about' ? 'active' : '',
    enquiries: activeParent === 'enquiries' ? 'active' : '',
  };

  let props = {
    onClick: hideOffCanvas,
    activeClassName: 'active',
  };

  const menuText = offCanvas ? 'Close' : 'Menu';

  return (
    <>
      <header
        className={headerClass}
        onMouseEnter={handleHeaderHover}
        onMouseLeave={handleHeaderHover}
      >
        <div className="header__inner">
          <Link
            to="/"
            title="Howes & Landino"
            className={`header__logo ${parentLinkClasses.gallery}`}
            {...props}
            onClick={() => handleSubMenuClick()}
          >
            <span className="logo">
              <Logo color="#1D1D1B" />
            </span>

          </Link>

          <nav className={submenuContainerClass}>
              <ul>
                <li className={parentLinkClasses.gallery}>
                  <Link
                    to="/"
                    className={activeSubMenu === 'gallery' ? 'active' : ''}
                    onClick={() => handleSubMenuClick('gallery')}
                  >
                    Gallery
                  </Link>
                </li>
                <li className={parentLinkClasses.makers}>
                  <Link
                    to="/makers"
                    className={activeSubMenu === 'makers' ? 'active' : ''}
                    onClick={() => handleSubMenuClick('makers')}
                  >
                    Makers
                  </Link>
                </li>
                <li className={parentLinkClasses.about}>
                  <Link
                    to="/about"
                    className={activeSubMenu === 'about' ? 'active' : ''}
                    onClick={() => handleSubMenuClick('about')}
                  >
                    About
                  </Link>
                </li>
                <li className={parentLinkClasses.enquiries}>
                  <Link
                    to="/enquiries"
                    className={activeSubMenu === 'enquiries' ? 'active' : ''}
                    onClick={() => handleSubMenuClick('enquiries')}
                  >
                    Enquiries
                  </Link>
                </li>
              </ul>
            </nav>

          <div className="header__hamburger-container">
            <button
              onClick={toggleOffCanvas}
              className={offCanvas ? 'header__hamburger active' : 'header__hamburger'}
            >
              {menuText}
            </button>
          </div>
        </div>
      </header>

      <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`}>
        <div className='off-canvas__inner'>
          {/* <button className='off-canvas__menu-close' onClick={hideOffCanvas}>
            <Close color={'#1D1D1B'} />
          </button> */}

          <nav className='off-canvas__nav'>
              <ul>
              <li className={parentLinkClasses.gallery}>
                  <Link
                    to="/"
                    className={activeSubMenu === 'gallery' ? 'active' : ''}
                    onClick={() => handleSubMenuClick('gallery')}
                    {...props}
                  >
                    Gallery
                  </Link>
                </li>
                <li className={parentLinkClasses.makers}>
                  <Link
                    to="/makers"
                    className={activeSubMenu === 'makers' ? 'active' : ''}
                    onClick={() => handleSubMenuClick('makers')}
                    {...props}
                  >
                    Makers
                  </Link>
                </li>
                <li className={parentLinkClasses.about}>
                  <Link
                    to="/about"
                    className={activeSubMenu === 'about' ? 'active' : ''}
                    onClick={() => handleSubMenuClick('about')}
                    {...props}
                  >
                    About
                  </Link>
                </li>
                <li className={parentLinkClasses.enquiries}>
                  <Link
                    to="/enquiries"
                    className={activeSubMenu === 'enquiries' ? 'active' : ''}
                    onClick={() => handleSubMenuClick('enquiries')}
                    {...props}
                  >
                    Enquiries
                  </Link>
                </li>
                <li><Link to='https://taylorhowes.co.uk' target="_blank" {...props}>Taylor Howes</Link></li>
              </ul>
            </nav>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  setSortBy: PropTypes.func.isRequired,
};

export default Header;